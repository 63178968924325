<template>
  <div>
    <modal name="catalogoEdit" height="auto" width="900" :scrollable="true" :clickToClose="false">
      <CloseModal :close="close"/>
      <div class="modalbox">
        <div class="modalbox-content">
          <CTabs variant="tabs" class="nav-tabs-boxed">
            <CTab :title="`Criando ${descSingular}`">
              <CCol>
                <CAlert show color="info" v-if="item.tipo =='MANUAL'">
                  <p><strong>Orientações para envio dos arquivos manualmente:</strong></p>
                  <p><strong>Passo 1 -</strong> Enviar os arquivos para a pasta <strong>"{{ item.pathApp }}"</strong>
                    <br>
                    <strong>Obs 1:</strong> Se o catálogo a ser criado for uma revista que contenha subpastas, na raiz
                    da pasta <strong>"{{ item.id }}"</strong> deve conter um arquivo chamado <strong>index.html</strong>.
                  </p>
                  <p><strong>Obs 2:</strong> Se o arquivo enviado for um PDF, colocar o nome do arquivo de
                    <strong>"{{ item.id }}.pdf"</strong></p>
                </CAlert>
                <CAlert show color="info" v-if="item.modo =='1'">
                  <p><strong>Orientações de criação para catálogo manualmente:</strong></p>
                  <p>Primeiramente é necessário o cadastro do catálogo para poder gerar a estrutura de envio dos
                    arquivos.<br>
                    Após a criação clique no botão <strong>Editar</strong> no respectivo catálago criado para ver as
                    próximas orientações.
                  </p>
                </CAlert>
              </CCol>
              <div class="xrow">
                <div class="col-2-3">
                  <div class="form-group">
                    <label>Descrição</label>
                    <input class="form-control" v-model="item.descricao"/>
                    <div class="message">{{ validation.firstError('item.descricao') }}</div>
                  </div>
                </div>
                <div class="col-1-3">
                  <div class="form-group">
                    <label>Ordem</label>
                    <input type="number" class="form-control" v-model="item.ordem"/>
                    <div class="message">{{ validation.firstError('item.ordem') }}</div>
                  </div>
                </div>
                <div class="col-1-3">
                  <div class="form-group">
                    <label>Ativo</label>
                    <select class="form-control" v-model="item.ativo">
                      <option value="1">Sim</option>
                      <option value="0">Não</option>
                    </select>
                    <div class="message">{{ validation.firstError('item.ativo') }}</div>
                  </div>
                </div>
                <div class="col-1-3" v-if="!item.id">
                  <div class="form-group">
                    <label>Modo</label>
                    <select class="form-control" v-model="item.modo">
                      <option value="0">Normal</option>
                      <option value="1">Manual</option>
                    </select>
                    <div class="message">{{ validation.firstError('item.ativo') }}</div>
                  </div>
                </div>

                <div class="col-1-3" v-if="item.modo ==='1'">
                  <div class="form-group">
                    <label>Extensão do arquivo</label>
                    <select class="form-control" v-model="item.extTipo">
                      <option value="0">HTML</option>
                      <option value="1">PDF</option>
                    </select>
                    <div class="message">{{ validation.firstError('item.ativo') }}</div>
                  </div>
                </div>
                <div class="col-1-3" v-if="item.modo!='1' && item.tipo !='MANUAL'">
                  <div class="form-group">
                    <label>Arquivo(PDF ou HTML)</label>
                    <input type="file" accept=".pdf,.html" class="form-control" v-on:change="setFile"/>
                    <label class="message" v-show="arquivoValidate">{{ arquivoValidate }}</label>
                  </div>
                </div>
                <div class="col-1-1 add-image">
                  <label>
                    Adicionar Imagem
                    <image-picker
                        label="Imagem Capa"
                        placeholder="Colar URL ou clique ao lado para upload"
                        v-model="item.imagem_capa"
                    />
                  </label>
                </div>
                <div class="col-1-3">
                  <div class="form-group">
                    <label>Data Publicação</label>
                    <datetime
                        inputId="data_publicacaoFTP"
                        input-class="form-control"
                        type="date"
                        v-model="item.data_publicacao"
                        :phrases="{ok: 'Confirmar', cancel: 'Fechar'}"
                        value-zone="local"
                    />
                    <div class="message">{{ validation.firstError('item.data_publicacao') }}</div>
                  </div>
                </div>
              </div>
              <div class="xrow edit-buttons">
                <button :class="{ 'button button-success': true, 'button-disabled': validation.hasError() }"
                        v-on:click="handleSubmit">
                  Salvar
                </button>
                &nbsp;
                <button class="button" v-on:click="close">Fechar</button>
              </div>

            </CTab>
          </CTabs>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import {Validator} from 'simple-vue-validator';
import CloseModal from '../../../components/CloseModal';
import ImagePicker from '../../../components/ImagePicker';

export default {
  name: 'catalogoEdit',
  props: ['item', 'update', 'close', 'modulos', 'descSingular'],
  components: {CloseModal, ImagePicker},
  data() {
    return {
      arquivoValidate: ''
    };
  },
  computed: {},
  mounted() {
  },
  validators: {},
  methods: {
    handleSubmit() {
      this.update();
    },
    setFile(e) {
      // Verifica se o arquivo é maior que 20mb
      if (e.target.files[0].size > 20971520) {
        this.arquivoValidate = 'Arquivo excede o tamanho máximo permitido. (20MB)';
        return;
      }

      this.arquivoValidate = '';
      this.item.arquivo = e.target.files[0];
      this.item.arquivo_nome = e.target.files[0].name;
    }
  }
};
</script>
