<template>
    <div>
        <div class="filters row" style="margin-top:0">
            <div class="col-1-1">
                <div class="pull-right">
                    <button v-on:click="openCreate" class="button button-success button-block">
                        <fa-icon icon="plus"/>
                        Criar
                    </button>
                </div>
            </div>
        </div>
        <div class="table-responsive">
            <table class="table table-hover">
                <thead>
                <tr>
                    <th>Imagem</th>
                    <th>Arquivo</th>
                    <th>Tipo</th>
                    <th>Descrição</th>
                    <th>Ordem</th>
                    <th>Status</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="item in items">
                    <td>
                        <img class="img-thumbnail" :src="item.imagem_capa"/>
                    </td>
                    <td>
                        <a target="_blank" :href="item.url">
                            <CButton class="btn btn-sm btn-link">
                                <CIcon name="cil-file"/>
                                Prévia
                            </CButton>
                        </a>
                    </td>
                    <td>
                        <span class="label warning">{{ item.tipo }}</span>
                    </td>
                    <td>{{ item.descricao }}</td>
                    <td>
                        <span class="label primary">{{ item.ordem }}</span>
                    </td>
                    <td>
                        <span class="label success" v-if="item.ativo">Ativo</span>
                        <span class="label error" v-else>Inativo</span>
                    </td>
                    <td class="actions">
                        <button type="button" class="button button-primary" v-on:click="openEdit(item)">
                            <fa-icon icon="pencil-alt"/>
                        </button>
                        <button type="button" name="button" v-on:click="deleteConfirm(item.id)"
                                class="button button-error">
                            <fa-icon icon="trash-alt"/>
                        </button>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
  export default {
    name: 'list',
    props: ['items', 'openEdit', 'openCreate', 'deleteConfirm'],
    data() {
      return {}
    },
    computed: {},
    mounted() {
    },
    methods: {}
  }
</script>